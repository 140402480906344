import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, ViewChildren, QueryList, SimpleChanges, OnChanges, ViewChild, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { MapInfoWindow, MapMarker, MapDirectionsService, ClusterIconStyle, GoogleMap, } from '@angular/google-maps';
// import { GoogleMapsAPIWrapper, AgmMarker, MapsAPILoader  } from '@agm/core';
import { EnterpriseSearchService, HotelItinerary, WithSubscriptionComponent, HotelAvalibilityService, ServiceType } from '@sabstravtech/obtservices/angular';
import { HotelAvalibilityQuoteResult, HotelEnterpriseSearchInterface } from '@sabstravtech/obtservices/base';
import { ThemeService } from '../../../../vendor/services/theme.service';
declare var google: any;
@Component({
  selector: 'app-map-hotels',
  templateUrl: './map-hotels.component.html',
  styleUrls: ['./map-hotels.component.css']
})
export class MapHotelsComponent extends WithSubscriptionComponent implements OnInit, AfterViewInit, OnChanges {
  @Output() afterBackToList: EventEmitter<null> = new EventEmitter();
  @Input() arrayOfHotels: HotelItinerary[];
  @Input()
  mapControl: {
    showMap: boolean;
    hotelChosen: number | boolean;
    hotelCodeChosen: string;
    ratesUpdated?: EventEmitter<boolean>;
    filterChange?: EventEmitter<HotelItinerary[]>;
    choseHotel?: EventEmitter<HotelItinerary>;
  };
  @Input() noOfRooms: number;
  @ViewChild(GoogleMap) map: GoogleMap;
  // @ViewChildren(AgmMarker) markers: QueryList<AgmMarker>;
  directionsResults$: Observable<google.maps.DirectionsResult | undefined>;
  mapOptions: google.maps.MapOptions = {};
  referencePoint: google.maps.LatLngLiteral;
  locations: google.maps.LatLngLiteral[] = [];
  label: string;
  error = false;
  centerInterval: any;
  clusterStyles: ClusterIconStyle[] = [{
    height: 50,
    width: 50,
    textColor: 'white',
    url: "https://images.sabscorp.com/images/enterprise/lightUK/assets/png/marker-cluster.png",
    fontWeight: "bold",
    textSize: 20,
    anchorText: [13, 0]
  }];
  directionsOrigin: any = null;
  directionsOriginName: string = null;
  directionsDestination: any = null;
  directionsMode: string = google.maps.TravelMode.DRIVING;
  directionsURL: string = "";
  public searchParams: HotelEnterpriseSearchInterface;
  lastOpenedWindow: MapInfoWindow = null;

  @ViewChild('search') searchElementRef: ElementRef;
  @ViewChild('directionsPanel') directionsPanelElementRef: ElementRef;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  constructor(
    public mapDirectionsService: MapDirectionsService,
    public searchService: EnterpriseSearchService,
    // private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    protected hotelAvailabilityService: HotelAvalibilityService, 
    public themeService: ThemeService) {
    super();
    this.mapControl = {
      showMap: false,
      hotelChosen: null,
      hotelCodeChosen: null
    };
    this.searchParams = searchService.searches[ServiceType.Hotel];
  }

  ngOnInit(): void {
    this.updateLocations();
    this.subscribe(this.mapControl.filterChange, hotels => {
      if (hotels) {
        this.updateLocations(hotels);
      }
    });
    this.centerInterval = setInterval(() => {
      if (this.mapControl.hotelChosen === false) {
        return;
      }
      let hotel;
      if (this.arrayOfHotels.length === 1) {
        hotel = this.arrayOfHotels[0];
      } else {
        hotel = this.arrayOfHotels[+this.mapControl.hotelChosen];
      }
      this.updateLocations([hotel]);
      clearInterval(this.centerInterval);
    }, 100);

    // reference point
    this.referencePoint = {
      lat: this.searchParams.location ? this.searchParams.location.latitude : this.searchParams.office.latitude,
      lng: this.searchParams.location ? this.searchParams.location.longitude : this.searchParams.office.longitude
    };
  }

  ngAfterViewInit(): void {
    this.setupAddressSearch(google.maps.TravelMode.DRIVING);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.arrayOfHotels.currentValue) {
      this.updateLocations(changes.arrayOfHotels.currentValue);
    }
  }

  updateLocations(hotels: HotelItinerary[] = this.arrayOfHotels): void {
    setTimeout(() => {
      this.mapControl.ratesUpdated.emit(false);
    });
    this.error = false;
    if (!hotels.length) {
      return;
    }

    if (!hotels[0].id) {
      return;
    }

    if ((hotels[0].location.latitude && hotels[0].location.longitude)) {
      this.mapOptions = {
        center: { lat: +hotels[0].location.latitude, lng: +hotels[0].location.longitude },
        zoom: 13
      };
      this.locations = [];
      for (let i = 0; i < hotels.length; i++) {
        const code = hotels[i].id;
        const thumbUrl = `https://ssl.conferma.com/Images/PropertyThumbnails/${hotels[i].id.charAt(0)}/${hotels[i].id.charAt(1)}/${hotels[i].id}.jpg`;
        let availabilityResult: HotelAvalibilityQuoteResult = this.hotelAvailabilityService.getAvailabilityForId(this.searchParams.makeHotelAvalilityObject(hotels[i]), hotels[i])?.value;

        // const price = availabilityResult ? availabilityResult.maxprice * this.hotelObject.no_of_rooms : 0;
        let price = 0;
        let currencyCode = 'GB';
        const arOfRoomObjs = [];
        if (availabilityResult) {
          price = availabilityResult ? availabilityResult.maxprice : 0;
          currencyCode = availabilityResult.currencyCode;
          const arrOfRoomKeys = Object.keys(availabilityResult.rooms);
          for (let r = 0; r < arrOfRoomKeys.length; r++) {
            arOfRoomObjs.push(availabilityResult.rooms[arrOfRoomKeys[r]]);
          }
        }

        if (hotels[i].location) {
          const locTemp = {
            lat: +hotels[i].location.latitude,
            lng: +hotels[i].location.longitude,
            label: {
              color: 'purple',
              fontWeight: 'bold',
              fontSize: '12px',
              text: hotels[i].name
            },
            title: hotels[i].name,
            ThumbUrl: thumbUrl,
            roomDescs: arOfRoomObjs,
            hotelName: hotels[i].name,
            guidePrice: price, // hotels[i].TotalPrice,
            iconUrl: this.getIcon(this.getRoomDetails(availabilityResult), hotels[i]),
            currency: currencyCode,
            holidayCode: hotels[i].id,
            Code: code,
            index: this.arrayOfHotels.indexOf(hotels[i]),
            stars: hotels[i].stars,
            price: (availabilityResult && availabilityResult.rooms && availabilityResult.rooms.length) ? availabilityResult.rooms[0].total : null
          };

          this.locations.push(locTemp);
        } else {
          console.log('Error cannot find Geocode for this hotel: ', i);
        }
      }
    } else {
      console.log('Error: Cannot find Geocodes!');
      this.error = true;
    }
  }

  public getRoomDetails(hotel): Observable<string | boolean> {
    return this.mapControl.ratesUpdated.pipe(
      map(value => {
        if (hotel?.rooms) {
          return hotel.rooms;
        } else {
          if (!value) {
            return 'Loading';
          } else {
            return false;
          }
        }
      })
    );
  }

  public openDirections(markerObj: { lat: number; lng: number; index: number | boolean; holidayCode: string; title: string; }): void {
      this.directionsOrigin = {
        lat: markerObj.lat,
        lng: markerObj.lng
      };
      this.directionsOriginName = markerObj.title;
  }

  public openDetails(markerObj: { lat: number; lng: number; index: number | boolean; holidayCode: string; title: string; }): void {
    //this.setMapCenter(`${markerObj.lat},${markerObj.lng}`);
    this.mapControl.hotelChosen = markerObj['index'];
    this.mapControl.hotelCodeChosen = markerObj['holidayCode'];
    this.mapControl.showMap = false;
  }

  public setMapCenter(geocode: string): void {
    const position = new google.maps.LatLng(geocode.split(',').shift(), geocode.split(',').pop());
    this.map.panTo(position);
  }

  public getIcon(roomObservable: Observable<string | boolean>, hotel: HotelItinerary): Observable<string> {
    return roomObservable.pipe(
      map(roomDetails => {
        if (roomDetails === 'Loading') {
          return `https://chart.apis.google.com/chart?chst=d_map_spin&chld=1.0|0|d0d0d0|8|b|Loading`;
        }
        if (roomDetails[0]?.total && roomDetails[0]?.currencyCode) {
          let pinName;
          if (roomDetails[0].unavailable) {
            if (hotel.preferred.client) {
              pinName = this.themeService.isScionEnv ? 'hotel-map-pin-red-heart-generic.png' : 'hotel-map-pin-red-heart.png';
            } else if (hotel.preferred.consumer) {
              pinName = this.themeService.isScionEnv ? 'hotel-map-pin-red-heart-generic.png' : 'hotel-map-pin-red-ctm.png';
            } else {
              pinName = 'hotel-map-pin-red.png';
            }
          } else if (roomDetails[0].requiresReasonKeys?.length) {
            if (hotel.preferred.client) {
              pinName = this.themeService.isScionEnv ? 'hotel-map-pin-orange-heart-generic.png' : 'hotel-map-pin-orange-heart.png';
            } else if (hotel.preferred.consumer) {
              pinName = this.themeService.isScionEnv ? 'hotel-map-pin-orange-heart-generic.png' : 'hotel-map-pin-orange-ctm.png';
            } else {
              pinName = 'hotel-map-pin-orange.png';
            }
          } else {
            if (hotel.preferred?.client) {
              pinName = this.themeService.isScionEnv ? 'hotel-map-pin-green-heart-generic.png' : 'hotel-map-pin-green-heart.png';
            } else if (hotel.preferred.consumer) {
              pinName = this.themeService.isScionEnv ? 'hotel-map-pin-green-heart-generic.png' : 'hotel-map-pin-green-ctm.png';
            } else {
              pinName = 'hotel-map-pin-green.png';
            }
          }
          return `https://images.sabscorp.com/images/enterprise/lightUK/assets/png/${pinName}`;
        } else {
          return `https://images.sabscorp.com/images/enterprise/lightUK/assets/png/hotel-map-pin-loading.png`;
        }
      })
    );
  }

  public loadAPIWrapper(map): void {
    //this.map = map;
  }

  public backToList(): void {
    this.afterBackToList.emit();
  }

  openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow): void {
    if (this.lastOpenedWindow) {
      this.lastOpenedWindow.close();
    }
    this.lastOpenedWindow = infoWindow;
    infoWindow.open(marker);
  }

  setupAddressSearch(directionsMode: string): void {
    if (directionsMode !== this.directionsMode) {
      this.resetPanel();
      this.directionsMode = directionsMode;
      this.setupDirectionsResults();
    }

    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener("place_changed", () => {
      this.resetPanel();
      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        this.directionsDestination = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
        this.directionsURL = `http://maps.google.com/maps?saddr=${this.directionsOrigin.lat},${this.directionsOrigin.lng}&daddr=${this.directionsDestination.lat},${this.directionsDestination.lng}`;
        this.setupDirectionsResults();
      });
    });
  }

  private resetPanel(): void {
    const panelElement: HTMLElement = this.directionsPanelElementRef.nativeElement;
    panelElement.innerHTML = '';
  }

  private setupDirectionsResults(): void {
    const request: google.maps.DirectionsRequest = {
      destination: this.directionsDestination,
      origin: this.directionsOrigin,
      travelMode: google.maps.TravelMode[this.directionsMode]
    };
    this.directionsResults$ = this.mapDirectionsService.route(request).pipe(map(response => response.result));
  }

  clearHotelDirections(): void {
    this.map.googleMap.setCenter(this.directionsOrigin);
    this.map.googleMap.setZoom(15);
    this.directionsResults$ = null;
    this.directionsDestination = null;
    this.directionsOrigin = null;
    this.directionsOriginName = null;
    this.directionsMode = google.maps.TravelMode.DRIVING;
  }
}