import { Suppliers, UserFavorurite } from '@sabstravtech/obtservices/base';

export class LightningUserFavorurite extends UserFavorurite {
  static additionalFiletersHotel = new UserFavorurite('additionalFiletersHotel');
  static carnet_tickets = new UserFavorurite('evolviCarnetTickets');
  static defaultLanguage = new UserFavorurite('defaultLanguage');
  static DefaultTimeInbound = new UserFavorurite('DefaultTimeInbound');
  static DefaultTimeInboundRail = new UserFavorurite('DefaultTimeInboundRail');
  static DefaultTimeOutbound = new UserFavorurite('DefaultTimeOutbound');
  static DefFlightTimePeriod = new UserFavorurite('DefFlightTimePeriod');
  static EnableFreshChat = new UserFavorurite('enableFreshChat');
  static HideCarHireOptions = new UserFavorurite('HideCarHireOptions');
  static HideFlightFarePreferences = new UserFavorurite('HideFlightFarePreferences');
  static HideFlightVia = new UserFavorurite('HideFlightVia');
  static hideHotelSearchOptions = new UserFavorurite('hideHotelSearchOptions');
  static hotelFilterDefaultAvailability = new UserFavorurite('hotelFilterDefaultAvailability');
  static languages = new UserFavorurite('languages');
  static LU2079GenerateErrors = new UserFavorurite('LU2079GenerateErrors');
  static no_add_guest = new UserFavorurite('no_add_guest');
  static no_open_returns = new UserFavorurite('no_open_returns');
  static PreferredCarVendors = new UserFavorurite('PreferredCarVendors');
  static preferredLogoImagePath = new UserFavorurite('preferredLogoImagePath');
  static sendTescoCarHire = new UserFavorurite('sendTescoCarHire');
  static ShowFlightAnytimePeriod = new UserFavorurite('ShowFlightAnytimePeriod');
  static startTab = new UserFavorurite('startTab');
  static taxiAlwaysCall = new UserFavorurite('taxiAlwaysCall');
  static themename = new UserFavorurite('themeName');
  static ShowRiskAlert = new UserFavorurite('showRiskAlerts');
  static homepage: UserFavorurite = new UserFavorurite('homepage');
  static HideCovidTest: UserFavorurite = new UserFavorurite('hideCovidTest');
  static PreferredCurrency: UserFavorurite = new UserFavorurite('preferredCurrency');
  static RailSearchFormConfiguration = new UserFavorurite('railSearchFormConfiguration');
  static MultiBasketConfiguration = new UserFavorurite('multiBasket');
  static HotelResultsFormConfiguration = new UserFavorurite('hotelPreferredImage');
  static FlightResultsConfiguration = new UserFavorurite('flightResultsConfiguration');
  static ClientCompanyLogo = new UserFavorurite('clientCompanyLogo');
  static DisplayFCDOAdvice = new UserFavorurite('displayFCDOAdvice');
  static CarHireResultsConfiguration = new UserFavorurite('carHireResultsConfiguration');
  static UsefulInformation = new UserFavorurite('usefulInformation');
  static AllowAgentToViewSeatMaps = new UserFavorurite('allowAgentToViewSeatMaps');
  static scionDashboardInfo = new UserFavorurite('scionDashboardInfo');
  static defaultAddTraveller = new UserFavorurite('defaultAddTraveller');
  static EnableDoorToDoor = new UserFavorurite('enableDoorToDoor');
}

export class LightningSuppliers extends Suppliers {
  protected static empty = new Suppliers('empty');
}

