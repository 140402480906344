import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-with-error',
  templateUrl: './image-with-error.component.html',
  styleUrls: ['./image-with-error.component.css']
})
export class ImageWithErrorComponent implements OnInit {
  @Input() title: string;
  @Input() logoUrl: string;
  @Input() errorUrl: string;
  @Input() errorIcon: string;
  @Input() alt: string;
  @Input() componentId: string;
  invalidImage: boolean = true;
  preferredLogoUrl: string = '';
  constructor() {}

  ngOnInit(): void {}

  logoLoadError(): void {
    this.invalidImage = true;
  }

  logoLoader(): void {
    this.invalidImage = false;
  }
}
