<div class="modal-header">
  <h3 class="modal-title">
    {{title | translate}} <span i18n="@@galleryTitle">Photos</span>
  </h3>
</div>
<div class="modal-body">
  <ng-container *ngIf="photos">
    <div class="gallery">
      <ng-container *ngFor="let image of photos; let index = index">
        <div [ngClass]="{'highlighted-item': image === selected}"
            (click)="selected = image"
            (keydown.enter)="selected = image"
            tabindex="0" role="button"
            aria-label="Select photo {{index + 1}} for display"
            [attr.aria-pressed]="image === selected">
          <img [src]="image" alt="Photo {{index + 1}}" >
        </div>
      </ng-container>
    </div>
    <div id="hotel-gallery-selected-image" class="selected-image" *ngIf="selected">
      <img [src]="selected" alt="Selected image" >
    </div>
  </ng-container>

  <ng-container *ngIf="!photos?.length">
    <p i18n="@@noPhotos">
      No photos...
    </p>
  </ng-container>
</div>
