import { ModalTypes } from '@sabstravtech/obtservices/base';

export class LightningModalTypes extends ModalTypes {
  static readonly ApproversModalComponent = new ModalTypes('ApproversModalComponent');
  static readonly BaseModalComponent = new ModalTypes('BaseModalComponent');
  static readonly ChoosePassengersComponent = new ModalTypes('ChoosePassengersComponent');
  static readonly IframeComponent = new ModalTypes('IframeComponent');
  static readonly InfoModalComponent = new ModalTypes('InfoModalComponent');
  static readonly MiGroupComponent = new ModalTypes('MiGroupComponent');
  static readonly ModalCardErrorComponent = new ModalTypes('ModalCardErrorComponent');
  static readonly ModalCardVerificationComponent = new ModalTypes('ModalCardVerificationComponent');
  static readonly ModalComponent = new ModalTypes('ModalComponent');
  static readonly ModalConfirmCardComponent = new ModalTypes('ModalConfirmCardComponent');
  static readonly ModalConfirmComponent = new ModalTypes('ModalConfirmComponent');
  static readonly ModalCustomErrorComponent = new ModalTypes('ModalCustomErrorComponent');
  static readonly ModalGuestTravellerComponent = new ModalTypes('ModalGuestTravellerComponent');
  static readonly ModalLanguageSaveComponent = new ModalTypes('ModalLanguageSaveComponent');
  static readonly ModalPasswordExpiryComponent = new ModalTypes('ModalPasswordExpiryComponent');
  static readonly ModalPasswordResetComponent = new ModalTypes('ModalPasswordResetComponent');
  static readonly ModalRailDetailsComponent = new ModalTypes('ModalRailDetailsComponent');
  static readonly ModalRailJourneyDetailsComponent = new ModalTypes(
    'ModalRailJourneyDetailsComponent'
  );
  static readonly ModalRailJourneyTicketTermsComponent = new ModalTypes(
    'ModalRailJourneyTicketTermsComponent'
  );
  static readonly ModalRailExchangeCompleted = new ModalTypes('ModalRailExchangeCompleted');
  static readonly ModalRailExchangeInformationComponent = new ModalTypes(
    'ModalRailExchangeInformationComponent'
  );
  static readonly ModalRailSeatReservationComponent = new ModalTypes(
    'ModalRailSeatReservationComponent'
  );
  static readonly ModalRecoverPasswordComponent = new ModalTypes('ModalRecoverPasswordComponent');
  static readonly ModalExpiredPasswordComponent = new ModalTypes('ModalExpiredPasswordComponent');
  static readonly ModalScreenreaderBasketComponent = new ModalTypes(
    'ModalScreenreaderBasketComponent'
  );
  static readonly ModalSearchErrorComponent = new ModalTypes('ModalSearchErrorComponent');
  static readonly ModalSupportEmailComponent = new ModalTypes('ModalSupportEmailComponent');
  static readonly ModalTescoCarHireEmailComponent = new ModalTypes(
    'ModalTescoCarHireEmailComponent'
  );
  static readonly ModalTravellerErrorComponent = new ModalTypes('ModalTravellerErrorComponent');
  static readonly ModalUserErrorComponent = new ModalTypes('ModalUserErrorComponent');
  static readonly SupplementalModalComponent = new ModalTypes('SupplementalModalComponent');
  static readonly ModalLoungeImagesComponent = new ModalTypes('ModalLoungeImagesComponent');
  static readonly ModalFastTrackImagesComponent = new ModalTypes('ModalFastTrackImagesComponent');
  static readonly MiModalComponent = new ModalTypes('MiModalComponent');
  static readonly RiskAlertDetail = new ModalTypes('RiskAlertDetailModalComponent');
  /// New
  static readonly ModalIframeComponent = new ModalTypes('ModalIframeComponent');
  static readonly HotelFacilitiesComponent = new ModalTypes('HotelFacilitiesComponent');
  static readonly RatesHotelComponent = new ModalTypes('RatesHotelComponent');
  static readonly AlternativeRailModalComponent = new ModalTypes('AlternativeRailModalComponent');
  static readonly FlightCostsComponent = new ModalTypes('FlightCostsComponent');
  static readonly ModalFlightFareComponent = new ModalTypes('ModalFlightFareComponent');
  static readonly ModalChangeBasketComponent = new ModalTypes('ModalChangeBasketComponent');
  static readonly ModalFareRulesComponent = new ModalTypes('ModalFareRulesComponent');
  static readonly ModalSearchTimeoutComponent = new ModalTypes('ModalSearchTimeoutComponent');
  static readonly RailTicketsDialogComponent = new ModalTypes('RailTicketsDialogComponent');
  static readonly ModalIrlFareComponent = new ModalTypes('ModalIrlFareComponent');
  static readonly PriceBreakdownDialogComponent = new ModalTypes('PriceBreakdownDialogComponent');
  static readonly ModalBrandedFareComponent = new ModalTypes('ModalBrandedFareComponent');
  static readonly WarningModalComponent = new ModalTypes('WarningModalComponent');
  static readonly FlightExtrasDialogComponent = new ModalTypes('FlightExtrasDialogComponent');
  static readonly TermsAndConditionsDialogComponent = new ModalTypes(
    'TermsAndConditionsDialogComponent'
  );
  static readonly WarningRemoveModalComponent = new ModalTypes('WarningRemoveModalComponent');
  static readonly RailFareTermsComponent = new ModalTypes('RailFareTermsComponent');
  static readonly RailFareListComponent = new ModalTypes('RailFareListComponent');
  static readonly SeePhotosDialogComponent = new ModalTypes('SeePhotosDialogComponent');
  static readonly HotelInfoDialogComponent = new ModalTypes('HotelInfoDialogComponent');
  static readonly OfflineRequestDialogComponent = new ModalTypes('OfflineRequestDialogComponent');
  static readonly ApartmentRequestDialogComponent = new ModalTypes('ApartmentRequestDialogComponent');
  static readonly ModalHotelCheckComponent = new ModalTypes('ModalHotelCheckComponent');
  static readonly MybookingsRailCancelWarning = new ModalTypes('MybookingsRailCancelWarning');
  static readonly MybookingsRailCancelInfo = new ModalTypes('MybookingsRailCancelInfo');
  static readonly ModalSuccessComponent = new ModalTypes('ModalSuccessComponent');
  static readonly ManageBookingItemComponent = new ModalTypes('ManageBookingItemComponent');
  static readonly ModalLiveRailLegsComponent = new ModalTypes('ModalLiveRailLegsComponent');
  static readonly MyBookingsItemCancelWarning = new ModalTypes('MyBookingsItemCancelWarning');
  static readonly ModalLeaveItineraryComponent = new ModalTypes('ModalLeaveItineraryComponent');
  static readonly FlightItemCancelWarning = new ModalTypes('FlightItemCancelWarning');
  static readonly EurostarItemCancelWarning = new ModalTypes('EurostarItemCancelWarning');
  static readonly ModalLccMarkup = new ModalTypes('ModalLccMarkup');
  static readonly ScratchpadModalComponent = new ModalTypes('ScratchpadModalComponent');
  static readonly UpdateSelectionDialogComponent = new ModalTypes('UpdateSelectionDialogComponent');
  static readonly QuoteModalComponent = new ModalTypes('QuoteModalComponent');
  static readonly MyBookingsItemChangeOwnership = new ModalTypes('ItemChangeOwnershipComponent');
  static readonly ResendApprovalEmailComponent = new ModalTypes('ResendApprovalEmailComponent');
  static readonly AutoLoggingOutComponent = new ModalTypes('AutoLoggingOutComponent');
  static readonly MarkupDialogComponent = new ModalTypes('MarkupDialogComponent');
  static readonly ModalWizzairWarning = new ModalTypes('ModalWizzairWarning');
  static readonly CustomRemarksDialog = new ModalTypes('CustomRemarksDialog');
  static readonly ModalHotelPriceBreakdownComponent = new ModalTypes(
    'ModalHotelPriceBreakdownComponent'
  );
  static readonly SendPdfByEmailComponent = new ModalTypes('SendPdfByEmailComponent');
  static readonly BookingPriceChangesModalComponent = new ModalTypes(
    'BookingPriceChangesModalComponent'
  );
  static readonly RequiresOverrideReasonDialogComponent = new ModalTypes(
    'RequiresOverrideReasonDialogComponent'
  );

  static readonly BookingItemRefundInfoRailComponent = new ModalTypes(
    'BookingItemRefundInfoRailComponent'
  );
  static readonly ModalAmendHotelComponent = new ModalTypes('ModalAmendHotelComponent');
  static readonly ModalAmendRailComponent = new ModalTypes('ModalAmendRailComponent');
  static readonly ModalAmendTaxiComponent = new ModalTypes('ModalAmendTaxiComponent');
  static readonly ModalAmendFlightComponent = new ModalTypes('ModalAmendFlightComponent');
  static readonly ModalAmendedHotelInfoComponent = new ModalTypes('ModalAmendedHotelInfoComponent');
  static readonly ModalDeleteSavedAddresses = new ModalTypes('ModalDeleteSavedAddresses');
  static readonly FlightRepriceModalComponent = new ModalTypes('FlightRepriceModalComponent');
  static readonly ShareBasketModalComponent = new ModalTypes('ShareBasketModalComponent');
  static readonly ModalAmendRailSeatPrefComponent = new ModalTypes('AmendSeatPrefModalComponent');
  static readonly ModalAmendCarComponent = new ModalTypes('ModalAmendCarComponent');
  static readonly ResendConfirmationComponent = new ModalTypes('ResendConfirmationComponent');
  static readonly HotelMultiRoomDialogComponent = new ModalTypes('HotelMultiRoomDialogComponent');
  static readonly TaxiWarningModalComponent = new ModalTypes('TaxiWarningModalComponent');
  static readonly GmtHotelModalFareRulesComponent = new ModalTypes('GmtHotelModalFareRulesComponent');
  static readonly GmtCarModalFareRulesComponent = new ModalTypes('GmtCarModalFareRulesComponent');
  static readonly GmtLoungeModalFareRulesComponent = new ModalTypes('GmtLoungeModalFareRulesComponent');
  static readonly GmtParkingModalFareRulesComponent = new ModalTypes('GmtParkingModalFareRulesComponent');
  static readonly GmtFastTrackModalFareRulesComponent = new ModalTypes('GmtFastTrackModalFareRulesComponent');
  static readonly GmtTaxiModalFareRulesComponent = new ModalTypes('GmtTaxiModalFareRulesComponent');
  static readonly RoutehappyFlightModalComponent = new ModalTypes('RoutehappyFlightModalComponent');
  static readonly HotelResultModalComponent = new ModalTypes('HotelResultModalComponent');
  static readonly ModalHotelReviewsComponent = new ModalTypes('ModalHotelReviewsComponent');
  static readonly GmtFlightDetailsModalComponent = new ModalTypes('GmtFlightDetailsModalComponent');
  static readonly ApprovalWarningModalComponent = new ModalTypes('ApprovalWarningModalComponent');
  static readonly ModalDoorToDoorRailJourneyDetailsComponent = new ModalTypes('ModalDoorToDoorRailJourneyDetailsComponent');
  static readonly ModalCarDetailsComponent = new ModalTypes('ModalCarDetailsComponent');
  static readonly RailSeatAutoWarningModalComponent = new ModalTypes('RailSeatAutoWarningModalComponent');
  static readonly HotelSuggestionModalComponent = new ModalTypes('HotelSuggestionModalComponent');
  static readonly TaxiWheelchairModalComponent = new ModalTypes('TaxiWheelchairModalComponent');
  static readonly FlightUpsellDialogComponent = new ModalTypes('FlightUpsellDialogComponent');
}